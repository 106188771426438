import React from "react";
// import Button from "@material-ui/core/Button";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  texto: {
    textShadow: "-1px 1px 0 black",
    fontSize: 13,
    color: "white",
    padding: "3px 5px",
    fontFamily: "Rel-Extra-Bold",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      padding: "3px 5px",
      fontSize: 13,
      margin: "0 4px",
      letterSpacing: 1.5,
    },
    [theme.breakpoints.up("xl")]: {
      // padding: 5,
      // paddingLeft: 5,
      fontSize: 13,
      margin: "0 10px",
      letterSpacing: 1.5,
    },
  },
  beige: {
    color: "#d59570",
  },
  subTexto: {
    textShadow: "-1px 1px 0 black",
    fontSize: 12,
    color: "white",
    padding: "7px 20px",
    fontFamily: "Rel-Regular",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      padding: "7px 20px",
      // paddingLeft: 7,
      fontSize: 13,
      letterSpacing: 1.5,
    },
    "&:hover": {
      backgroundColor: "black",
      color: "#d59570",
    },
  },
  hr: {
    margin: "0 auto",
    visibility: "hidden",
    border: "solid 2px #d59570",
    width: "11%",
    MozTransition: "all 0.2s ease 0s",
    OTransition: "all 0.2s ease 0s",
    msTransition: "all 0.2s ease 0s",
    WebkitTransition: "all 0.2s ease 0s",
  },
  hover: {
    visibility: "visible",
    width: "80%",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
const Submenu = () => {
  const classes = useStyles();
 
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const selectPath = () => {
    // handleClose();
    setOpen(false);
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <div className={classes.root}>
      <div>
      <Link className={classes.link} to={"/wines"} component={RouterLink}>
        <div
          // onClick={()=>history.push("/wines")}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          style={{ padding: 5 }}
        >
          <div
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            // onClick={handleToggle}

            className={
              open === true ? clsx(classes.texto, classes.beige) : classes.texto
            }
          >
            WINES
          </div>
          <hr
            className={
              open === true ? clsx(classes.hr, classes.hover) : classes.hr
            }
          />
        </div>
        </Link>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ left: 45, marginTop: 7 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center bottom" : "center bottom",
              }}
            >
              <Paper
                style={{
                  backgroundColor: "rgba(32, 22, 19, 0.7)",
                  color: "white",
                }}
                onMouseEnter={handleToggle}
                onMouseLeave={handleToggle}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    // style={{ marginLeft: 50 }}
                  >
                     <Link className={classes.link} to={"/wines/magnanime"} component={RouterLink}>
                    <MenuItem
                    onClick={selectPath}
                      className={classes.subTexto}
                    >
                      MAGNÁNIME
                    </MenuItem>
                    </Link>
                    <Link className={classes.link} to={"/wines/unanime_signature"} component={RouterLink}>
                    <MenuItem
                      className={classes.subTexto}
                      onClick={selectPath}
                    >
                      UNÁNIME SIGNATURE
                    </MenuItem>
                    </Link>
                    <Link className={classes.link} to={"/wines/gran_vino_tinto"} component={RouterLink}>
                    <MenuItem
                      className={classes.subTexto}
                      onClick={selectPath}
                    >
                      GRAN VINO TINTO
                    </MenuItem>
                    </Link>
                    <Link className={classes.link} to={"/wines/unanime_malbec"} component={RouterLink}>
                    <MenuItem
                      className={classes.subTexto}
                      onClick={selectPath}
                    >
                      UNÁNIME MALBEC
                    </MenuItem>
                    </Link>
                    <Link className={classes.link} to={"/wines/unanime_pinot_noir"} component={RouterLink}>
                    <MenuItem
                      className={classes.subTexto}
                      onClick={selectPath}
                    >
                      UNÁNIME PINOT NOIR
                    </MenuItem>
                    </Link>
                    <Link className={classes.link} to={"/wines/unanime_chardonnay"} component={RouterLink}>
                    <MenuItem
                      className={classes.subTexto}
                      onClick={selectPath}
                    >
                      UNÁNIME CHARDONNAY
                    </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default Submenu;
