import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Portada from "../components/Portada";
import FichaVino from "../components/FichaVino";
import VysorVideo from "../components/VysorVideo";
import SlideStick from "../components/SlideStick";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // background: "rgba(117,84,67,1)",
    // background:
    //   "-moz-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-webkit-gradient(left top, right top, color-stop(0%, rgba(117,84,67,1)), color-stop(59%, rgba(151,99,69,1)), color-stop(100%, rgba(174,110,71,1)))",
    background:
      "-webkit-linear-gradient(left, rgba(150,119,96,1) 0%, rgba(168,133,106,1) 38%, rgba(199,162,133,1) 100%)",
    // background:
    //   "-o-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-ms-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "linear-gradient(to right, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // filter:
    //   "progid:DXImageTransform.Microsoft.gradient( startColorstr='#755443', endColorstr='#ae6e47', GradientType=1 )",
  },

  container: {
    position: "relative",
    maxWidth: 950,
    margin: "0 auto",
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },
  containerVino: {
    // display:"flex",
    textAlign: "center",
    // justifyContent:"center",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      alingItem: "flex-end",
    },
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      alingItem: "flex-end",
    },
  },
  imagenVino: {
    height: 400,
    margin: "0px auto",
    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
    [theme.breakpoints.up("md")]: {
      height: 550,
      marginTop: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      height: 700,
      marginTop: "auto",
    },
  },
  mancha: {
    position: "absolute",
    top: 0,
    height: 100,
    left: "50%",
    [theme.breakpoints.up("lg")]: {
      left: "70%",
      height: 300,
    },
  },
}));
const Chardonnay = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.root}>
       <Helmet>
   
        <title>Un&aacute;nime Chardonnay Wine</title>
        <meta
          name="description"
          content="Soils rich in calcium carbonate allow our Chardonnay to exhibit enticing aromas of bright citrus and tropical fruit, lively acidity and a distinct sensation of flinty minerality."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com/wines/unanime_chardonnay"
        />
      </Helmet>
      <Portada url={"chardonnay.jpg"} />
      <Grid container className={classes.container}>
        <img
          className={classes.mancha}
          alt="mancha"
          src={require("../assets/vinos/mancha_beige.png")}
        />
        <Grid item xs={12} md={6} className={classes.containerVino}>
          <img
            className={classes.imagenVino}
            alt={"vino chardonnay"}
            src={require("../assets/vinos/bottleshot_chardonnay.png")}
          />
        </Grid>
        <FichaVino
          titulo={"Unánime"}
          color={"black"}
          subtitulo={"CHARDONNAY"}
          composition={"100% Chardonnay"}
          apellation={"Uco Valley, Mendoza"}
          tasting={
            "Soils rich in calcium carbonate allow our Chardonnay to exhibit enticing aromas of bright citrus and tropical fruit, lively acidity and a distinct sensation of flinty minerality. Malolactic fermentation (30%) in French oak barrels and six months of ageing “sur lie” further adds attractive notes of brioche, vanilla and spice to the long, creamy textured finish."
          }
          puntos={"puntos_chardonnay.png"}
        />
      </Grid>

      <VysorVideo titulo={"IN OPI'S WORDS"} portada={"chardonnay"} vimeo={"455636584"}/>

      <SlideStick tipo={5} />
    </div>
  );
};

export default Chardonnay;
