import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import ZoomIn from "./ZoomIn";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 0",
    position: "relative",
    textAlign: "center",
    //   display:"flex",
    alignItems: "center",
    background: `url(${require("../assets/slide/fondo-grafito.jpg")})`,
    [theme.breakpoints.up("sm")]: {
      padding: "60px 0 100px 0",
    },
  },
  containerSlide: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1380,
      margin: "auto",
    },
  },
  title: {
    fontSize: 20,
    fontFamily: "Rel-Medium",
    color: "#d59570",
    margin: "10px auto",
    [theme.breakpoints.up("lg")]: {
      fontSize: 26,
      letterSpacing: 2,
    },
  },
  subtitle: {
    fontSize: 20,
    fontFamily: "Dis-Bold-Italic",
    color: "#ffffff",
    margin: "10px auto",
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      letterSpacing: 1.2,
    },
    [theme.breakpoints.up("lg")]: {
      margin: "10px auto 30px auto",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  arrow: {
    top: "30%",
    textAlign: "center",
    // margin:"auto 0",
    width: 30,
    height: 30,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      top: "40%",
    },
  },
}));

const CustomLeftArrow = (props) => {
  const classes = useStyles();
  const { style, onClick } = props;
  return (
    <IconButton
      color="inherit"
      // edge="start"
      onClick={onClick}
      className={classes.arrow}
      style={{
        ...style,
        position: "absolute",
        left: "calc(4% + 1px)",
        zIndex: 1000,
      }}
      // className={clsx(
      //   classes.menuButton,
      //   open && classes.hide,
      //   classes.sectionMobile
      // )}
    >
      <img
        alt={"arrow left"}
        style={{ width: "100%" }}
        src={require("../assets/slide/left.png")}
      />
    </IconButton>
  );
};
const CustomRightArrow = (props) => {
  const classes = useStyles();
  const { style, onClick } = props;
  return (
    <IconButton
      color="inherit"
      // edge="start"
      onClick={onClick}
      className={classes.arrow}
      style={{
        ...style,
        position: "absolute",
        right: "calc(4% + 1px)",
        zIndex: 1000,
      }}
      // className={clsx(
      //   classes.menuButton,
      //   open && classes.hide,
      //   classes.sectionMobile
      // )}
    >
      <img
        alt={"arrow left"}
        style={{ width: "100%" }}
        src={require("../assets/slide/right.png")}
      />
    </IconButton>
  );
};
const getWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
};
const arrayImage = [
  {
    image: require("../assets/slide/vino-2.jpg"),
    url: "/wines/unanime_signature",
  },
  {
    image: require("../assets/slide/vino-3.jpg"),
    url: "/wines/gran_vino_tinto",
  },
  {
    image: require("../assets/slide/vino-4.jpg"),
    url: "/wines/unanime_malbec",
  },
  {
    image: require("../assets/slide/vino-5.jpg"),
    url: "/wines/unanime_pinot_noir",
  },
  {
    image: require("../assets/slide/vino-6.jpg"),
    url: "/wines/unanime_chardonnay",
  },
  { image: require("../assets/slide/vino-1.jpg"), url: "/wines/magnanime" },
];
export function clone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  var temp = obj.constructor();
  for (var key in obj) {
    temp[key] = clone(obj[key]);
  }
  return temp;
}
const getArrayImage = (tipo) => {
  let imagenes = clone(arrayImage);
  for (var i = 0; i < tipo; i++) {
    imagenes.push(imagenes[0]);
    imagenes.shift();
  }
  return imagenes;
};
const SlideStick = ({ tipo }) => {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const initialWidth = getWidth();
  const [slidesToSlide, setSlidesToSlide] = useState(2);
  const [width, setwidth] = useState(initialWidth);
  useEffect(() => {
    callGetWidth();
    window.addEventListener("resize", callGetWidth);
    return () => {
      window.removeEventListener("resize", callGetWidth);
    }; // eslint-disable-next-line
  }, [width]);

  useEffect(() => {
    let imagenes = getArrayImage(tipo);
    setImages(imagenes); // eslint-disable-next-line
    callGetWidth(); // eslint-disable-next-line
  }, []);

  let callGetWidth = () => {
    setwidth(getWidth());
    // if (width < 464) setSlidesToSlide(2);
    if (width > 463) setSlidesToSlide(2);
    if (width > 1023) setSlidesToSlide(3);

    console.log(slidesToSlide);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToSlide,
    slidesToScroll: slidesToSlide,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
  };
  return (
    <div className={classes.container}>
      <div className={classes.containerSlide}>
        <Typography className={classes.title}>UNÁNIME WINES</Typography>
        <Typography className={classes.subtitle}>
          A UNANIMOUS DECISION
        </Typography>
        <Slider id="slide-stick" {...settings}>
          {images.map(({ image, url }, index) => (
            <ZoomIn key={index} image={image} url={url} />
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default SlideStick;
