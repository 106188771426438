import React from "react";
import { makeStyles } from "@material-ui/core/styles";


const Portada = ({ url }) => {
  const mobile = require(`../assets/portadas/mobile/${url}`)
  const desktop =require(`../assets/portadas/desktop/${url}`)
  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundImage: `url(${mobile})`,
      backgroundSize: "cover",
      height: "60vh",
      minHeight:150,
      backgroundPositionX: "50%",
      [theme.breakpoints.up("sm")]: {
        backgroundImage: `url(${desktop})`,
        height: "70vh",
        minHeight:500,
      },
      [theme.breakpoints.up("md")]: {
        backgroundImage: `url(${desktop})`,
        height: "80vh",
        minHeight:600,
      },
    },
  
    // sectionDesktop: {
    //   display: "none",
    //   [theme.breakpoints.up("sm")]: {
    //     display: "block",
    //   },
    // },
    // sectionMobile: {
    //   display: "block",
    //   [theme.breakpoints.up("sm")]: {
    //     display: "none",
    //   },
    // }
  }));
  const classes = useStyles();
  return <div className={classes.container}></div>;
};

export default Portada;
