import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  texto: {
    textShadow: "-1px 1px 0 black",
    fontSize: 13,
    color: "white",
    padding: 5,
    fontFamily: "Rel-Extra-Bold",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      padding: 5,
      paddingLeft: 5,
      fontSize: 13,
      margin: "0 4px",
      letterSpacing: 1.5,
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 10px",
    },
  },
  hr: {
    margin: "0 auto",
    visibility: "hidden",
    border: "solid 2px #d59570",
    width: "11%",
    MozTransition: "all 0.2s ease 0s",
    OTransition: "all 0.2s ease 0s",
    msTransition: "all 0.2s ease 0s",
    WebkitTransition: "all 0.2s ease 0s",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
const ButtonHover = ({ url, nombre }) => {

  const classes = useStyles();
  const hr = useRef(null);
  const button = useRef(null);
  const zoomIn = () => {
    hr.current.style.width = "80%";
    hr.current.style.visibility = "visible";
    button.current.style.color = "#d59570"
  };
  const zoomOut = () => {
    hr.current.style.width = "11%";
    hr.current.style.visibility = "hidden";
     button.current.style.color = "white"
  };
  return (
    <div>
       <Link className={classes.link} to={`/${url}`} component={RouterLink}>
      <Button
        onMouseEnter={zoomIn}
        onMouseLeave={zoomOut}
        className={classes.texto}
        ref={button}
     
      >
        {nombre}
      </Button>
      <hr ref={hr} className={classes.hr} />
      </Link>
    </div>
  );
};

export default ButtonHover;
