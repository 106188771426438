import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Dialog from "@material-ui/core/Dialog";
import { 
  // IconButton,
   Typography } from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
import Vimeo from "@u-wave/react-vimeo";
import { useInView } from 'react-intersection-observer'
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 700,
    margin: "50px auto",
    [theme.breakpoints.up("md")]: {
      margin: "50px auto",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 750,
      margin: "0px auto 70px auto",
    },
  },
  titulo: {
    color: "white",
    fontFamily: "Dis-Bold",
    fontSize: 20,
    margin: "10px 0",
    [theme.breakpoints.up("sm")]: {
      margin: "20px 0",
      fontSize: 30,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 30,
      letterSpacing: 1.2,
      margin: "30px 0",
    },
  },
  containerPortada: {
    backgroundColor:"black",
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
    width: 300,
    height: 170,
    [theme.breakpoints.up("sm")]: {
      width: 500,
      height: 250,
    },
    [theme.breakpoints.up("md")]: {
      width: 700,
      height: 380,
    },
    [theme.breakpoints.up("lg")]: {
      width: 920,
      height: 520,
    },
  },
  vimeo: {
    width: 300,
    height: 170,
    [theme.breakpoints.up("sm")]: {
      width: 500,
      height: 250,
    },
    [theme.breakpoints.up("md")]: {
      width: 700,
      height: 380,
    },
    [theme.breakpoints.up("lg")]: {
      width: 920,
      height: 520,
    },
  },
  foto: {
    position: "absolute",
    width: "100%",
    height: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: 0,
    MozTransition: "all 1s ease 0s",
    OTransition: "all 1s ease 0s",
    msTransition: "all 1s ease 0s",
    WebkitTransition: "all 1s ease 0s",
  },
  play: {
    width: 30,
    position: "absolute",
    left: 14,
    bottom: 24,
    opacity: 0.6,
    border: "1px solid #FFF",
    MozTransition: "all 0.25s ease 0s",
    OTransition: "all 0.25s ease 0s",
    msTransition: "all 0.25s ease 0s",
    WebkitTransition: "all 0.25s ease 0s",
    [theme.breakpoints.up("lg")]: {
      bottom: 14,
    },
  },
}));
const VysorVideo = ({ titulo, portada,vimeo }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const play = useRef(null);
  const foto = useRef(null);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const zoomIn = () => {
    play.current.style.opacity = "1";
    foto.current.style.width = "105%";
  };
  const zoomOut = (posicion) => {
    play.current.style.opacity = "0.6";
    foto.current.style.width = "100%";
  };
useEffect(() => {
  onBlurVideo()// eslint-disable-next-line
}, [inView]);
const onBlurVideo =()=>{
if (inView===false)handleClose()
}
  // useEventListener('mousemove', handler);
  // useEffect(() => {
  //   finVideo();
  // }, [open]);

  // function finVideo() {
  //   if (open === true) {
  //     var video = document.getElementById("myVideo");

  //     video.onended = function (e) {
  //       handleClose();
  //     };
  //   }
  // }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className={classes.container}>
        <Typography className={classes.titulo}>{titulo}</Typography>
        {open === false ? (
          <div
            onClick={handleClickOpen}
            onMouseEnter={zoomIn}
            onMouseLeave={zoomOut}
            className={classes.containerPortada}
            style={{ textAlign: "center" }}
          >
            <img
              ref={foto}
              width={"100%"}
              //   style={{ maxWidth: 900 }}
              className={classes.foto}
              alt={"portada"}
              src={require(`../assets/videos/portada_${portada}.jpg`)}
            />

            <img
              ref={play}
              className={classes.play}
              alt={"play"}
              src={require("../assets/videos/play-icon.svg")}
            />
          </div>
        ) : (
          <div className={classes.containerPortada} ref={ref}>
            <Vimeo
              video={vimeo}
              autoplay={true}
              className={classes.vimeo}
              onEnd={handleClose}
            />
            {/* <video
              id="myVideo"
              style={{ width: "100%" }}
              //   playsInline="playsinline"
              controls={true}
              autoPlay="autoplay"
              preload="none"
              //   muted="muted"
              // loop="loop"
              poster={require(`../assets/videos/portada_${portada}.jpg`)}
            >
              <source
                src={require(`../assets/videos/video_${portada}.mp4`)}
                type="video/mp4"
              />
            </video> */}
          </div>
        )}
        {/* <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          style={{
            backgroundColor: "#c28a6a",
            width: 50,
            height: 50,
            position: "fixed",
            top: 20,
            right: 20,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <video
          id="myVideo"
          style={{ width: "100%" }}
          //   playsInline="playsinline"
          controls={true}
          autoPlay="autoplay"
          preload="none"
          //   muted="muted"
          loop="loop"
          poster={require(`../assets/videos/portada_${portada}.jpg`)}
        >
          <source
            src={require(`../assets/videos/video_${portada}.mp4`)}
            type="video/mp4"
          />
        </video>
      </Dialog> */}
      </div>
    </div>
  );
};

export default VysorVideo;

// function useEventListener(eventName, handler, element = window) {
//   // Create a ref that stores handler
//   const savedHandler = useRef();

//   // Update ref.current value if handler changes.
//   // This allows our effect below to always get latest handler ...
//   // ... without us needing to pass it in effect deps array ...
//   // ... and potentially cause effect to re-run every render.
//   useEffect(() => {
//     savedHandler.current = handler;
//   }, [handler]);

//   useEffect(
//     () => {
//       // Make sure element supports addEventListener
//       // On
//       const isSupported = element && element.addEventListener;
//       if (!isSupported) return;

//       // Create event listener that calls handler function stored in ref
//       const eventListener = (event) => savedHandler.current(event);

//       // Add event listener
//       element.addEventListener(eventName, eventListener);

//       // Remove event listener on cleanup
//       return () => {
//         element.removeEventListener(eventName, eventListener);
//       };
//     },
//     [eventName, element] // Re-run if eventName or element changes
//   );
// }
