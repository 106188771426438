import React from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import ZoomInHome from "./ZoomInHome";
import clsx from "clsx";
const arrayImage = [
  { image: require("../assets/slide/vino-1.jpg"), url: "/wines/magnanime" },
  {
    image: require("../assets/slide/vino-2.jpg"),
    url: "/wines/unanime_signature",
  },
  {
    image: require("../assets/slide/vino-3.jpg"),
    url: "/wines/gran_vino_tinto",
  },
  {
    image: require("../assets/slide/vino-4.jpg"),
    url: "/wines/unanime_malbec",
  },
  {
    image: require("../assets/slide/vino-5.jpg"),
    url: "/wines/unanime_pinot_noir",
  },
  {
    image: require("../assets/slide/vino-6.jpg"),
    url: "/wines/unanime_chardonnay",
  },
];
const arrayImageMobile = [
  { image: require("../assets/slide/vino-mobile-1.jpg"), url: "/wines/magnanime" },
  {
    image: require("../assets/slide/vino-mobile-2.jpg"),
    url: "/wines/unanime_signature",
  },
  {
    image: require("../assets/slide/vino-mobile-3.jpg"),
    url: "/wines/gran_vino_tinto",
  },
  {
    image: require("../assets/slide/vino-mobile-4.jpg"),
    url: "/wines/unanime_malbec",
  },
  {
    image: require("../assets/slide/vino-mobile-5.jpg"),
    url: "/wines/unanime_pinot_noir",
  },
  {
    image: require("../assets/slide/vino-mobile-6.jpg"),
    url: "/wines/unanime_chardonnay",
  },
];

const useStyles = makeStyles((theme) => ({
  vinosContainer: {
    textAlign: "center",
    padding: "40px 20px",
    background: `url(${require("../assets/slide/fondo-grafito.jpg")})`,
    [theme.breakpoints.up("sm")]: {
      padding: "70px 30px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "100px 50px",
    },
  },
  vinosTitulo: {
    fontFamily: "Dis-Bold",
    fontSize: 23,
    color: "#ffffff",
    letterSpacing: 3,
    marginBottom: 20,
    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 36,
      letterSpacing: 1.2,
    },
  },
  vinosParrafo: {
    fontFamily: "Rel-Regular",
    color: "#a1a1a1",
    fontSize: 13,
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      padding: "10px 20px",
      margin: "15px auto",
      fontSize: 15,
      maxWidth: 600,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 25px",
      lineHeight: "34px",
      fontSize: 15,
      letterSpacing: 1.1,
    },
  },
  vinosZoom: {
    margin: "5px 0",
    // [theme.breakpoints.up("sm")]: {
    //   margin: "15px 0",
    // },
    [theme.breakpoints.up("md")]: {
      margin: "10px 0",
    },
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
const SectionWines = () => {
  const classes = useStyles();
  return (
    <div className={classes.vinosContainer}>
      <Typography className={classes.vinosTitulo}>OUR WINES</Typography>
      <Typography
        className={clsx(classes.vinosParrafo, classes.sectionDesktop)}
      >
        In a consistent pursuit of excellence, we make unique, prestigious,
        <br />
        award-winning wines. Wines defined by a strong and powerful personality,
        <br />the result of privileged terroirs and unwavering care from our team.
      </Typography>
      <Typography className={clsx(classes.vinosParrafo, classes.sectionMobile)}>
        In a consistent pursuit of excellence, we make unique, prestigious,
        award-winning wines. Wines defined by a strong and powerful personality,
        the result of privileged terroirs and unwavering care from our team.
      </Typography>
      <Hidden smUp>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ maxWidth: 970, margin: "0 auto" }}
      >
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImageMobile[0].image} url={arrayImageMobile[0].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImageMobile[1].image} url={arrayImageMobile[1].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImageMobile[2].image} url={arrayImageMobile[2].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImageMobile[3].image} url={arrayImageMobile[3].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImageMobile[4].image} url={arrayImageMobile[4].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImageMobile[5].image} url={arrayImageMobile[5].url} />
        </Grid>
      </Grid>
      </Hidden>
      <Hidden xsDown>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ maxWidth: 970, margin: "0 auto" }}
      >
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImage[0].image} url={arrayImage[0].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImage[1].image} url={arrayImage[1].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImage[2].image} url={arrayImage[2].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImage[3].image} url={arrayImage[3].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImage[4].image} url={arrayImage[4].url} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} className={classes.vinosZoom}>
          <ZoomInHome image={arrayImage[5].image} url={arrayImage[5].url} />
        </Grid>
      </Grid>
      </Hidden>
    </div>
  );
};
SectionWines.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(SectionWines);

