import React from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
// import YouTubeIcon from "@material-ui/icons/YouTube";
import Link from "@material-ui/core/Link";
import { Divider, 
  // IconButton,
   Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    backgroundColor: "#2b2b25",
    overflow: "hidden",
    padding: "5px 10px",
    paddingBottom: 30,
    paddingTop: 40,
    [theme.breakpoints.up("sm")]: {
      padding: "30px 10px",
      paddingTop: 50,
    },
  },
  logoMascota: {
    width: 70,
    [theme.breakpoints.up("sm")]: {
      width: 100,
    },
  },
  logoUnanime: {
    width: 110,
    [theme.breakpoints.up("lg")]: {
      width: 140,
    },
  },
  redes: {
    position: "relative",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: 0,
      top: 10,
      marginRight: -40,
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 0,
    },
  },
  containerMascota: {
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      textAlign: "initial",
    },
  },
  containerUnanime: {
    textAlign: "center",
    display: "grid",
    alignItems: "flex-end",
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      alignItems: "flex-end",
    },
  },
  containerRedes: {
    textAlign: "center",
    display: "grid",
    alignItems: "flex-end",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
  },
  containerFollow: {
    textAlign: "center",
    width: 140,
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      // width: 130,
      // display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      // textAlign: "initial",
      // display:"grid",
      // alignItems:"flex-end"
    },
  },
  textUnanime: {
    padding: "6px 1px",
    display: "block",
    color: "#FFFFFF",
    fontSize: 11,
    fontFamily: "Rel-Bold",
    minHeight:48,
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        color: "#d59570",
      }
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      padding: "6px 8px",
    },
   
  },
  textFollow: {
    color: "#d59570",
    fontSize: 14,
    fontFamily: "Rel-Bold",
  },
  textCondition: {
    display: "block",
    color: "#FFFFFF",
    fontSize: 12,
    fontFamily: "Rel-Regular",
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },
  divider: {
    width: "100%",
    color: "#d59570",
    backgroundColor: "#d59570",
    margin: "10px auto",
    [theme.breakpoints.up("md")]: {
      margin: "30px auto",
    },
  },
  arrow: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  

  return (
    <Grid
      container
      className={classes.container}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        style={{
          width: "100%",
          top: 0,
          position: "absolute",
          textAlign: "center",
          background:
            "linear-gradient(to right, rgba(198,172,94,1) 0%, rgba(198,172,94,1) 29%, rgba(254,246,231,1) 56%, rgba(213,186,147,1) 77%, rgba(146,93,29,1) 100%)",
          height: 10,
        }}
      ></Grid>
      <Grid container style={{ maxWidth: 900 }}>
        <Hidden smDown>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            className={classes.containerMascota}
          >
             <Link
            className={classes.link}
            to={"/"}
            component={RouterLink}
          >
            <Button >
              <img
                className={classes.logoMascota}
                src={require("../assets/logo_mascota.png")}
                alt="imagen home"
              />
            </Button>
            </Link>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={6}
          className={classes.containerUnanime}
        >
             <Link
            className={classes.link}
            to={"/"}
            component={RouterLink}
          >
          <Button style={{minHeight:48}} 
          >
            <img
              className={classes.logoUnanime}
              src={require("../assets/logo_unanime.png")}
              alt="UNANIME"
            />
          </Button>
          </Link>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Button
              onClick={() => history.push("/unanime")}
              className={classes.textUnanime}
            >
              UNÁNIME
            </Button>
            <Typography 
            // className={classes.textUnanime}
            style={{ color: "#FFFFFF",
            fontSize: 11,
            padding: "6px 1px",
            fontFamily: "Rel-Bold"}}
            >|</Typography> */}
             <Link
            className={classes.link}
            to={"/wines"}
            component={RouterLink}
          >
            <Button
            
              className={classes.textUnanime}
            >
              WINES
            </Button>
            </Link>
            <Typography 
            // className={classes.textUnanime}
              style={{ color: "#FFFFFF",
              fontSize: 11,
              padding: "6px 3px 6px 0px",
              fontFamily: "Rel-Bold"}}
            >|</Typography>
              <Link
            className={classes.link}
            to={"/winemaker"}
            component={RouterLink}
          >
            <Button
            
              className={classes.textUnanime}
            >
              WINEMAKER
            </Button>
            </Link>
            <Typography 
            // className={classes.textUnanime}
            style={{ color: "#FFFFFF",
            fontSize: 11,
            padding: "6px 0px 6px 3px",
            fontFamily: "Rel-Bold"}}
            >|</Typography>
             <Link
            className={classes.link}
            to={"/origin"}
            component={RouterLink}
          >
            <Button
        
              className={classes.textUnanime}
            >
              ORIGIN
            </Button>
            </Link>
            <Typography 
            // className={classes.textUnanime}
            style={{ color: "#FFFFFF",
            fontSize: 11,
            padding: "6px 1px",
            fontFamily: "Rel-Bold"}}
            >|</Typography>
            <Link
            className={classes.link}
            to={"/scores"}
            component={RouterLink}
          >
            <Button
            
              className={classes.textUnanime}
            >
              SCORES
            </Button>
            </Link>
            <Typography 
            // className={classes.textUnanime}
            style={{ color: "#FFFFFF",
            fontSize: 11,
            padding: "6px 1px",
            fontFamily: "Rel-Bold"}}
            >|</Typography>
            <Link
            className={classes.link}
            to={"/find_us"}
            component={RouterLink}
          >
            <Button
          
              className={classes.textUnanime}
            >
              FIND US
            </Button>
            </Link>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          className={classes.containerRedes}
        >
          <div className={classes.containerFollow}>
            <Typography className={classes.textFollow}>FOLLOW US</Typography>
            <div>
              {/* <Link target="_blank" rel="noopener" href="https://facebook.com">
                <IconButton
                  color="inherit"
                  edge="start"
                  className={classes.arrow}
                  style={{}}
                >
                  <FacebookIcon
                    style={{
                      color: "#686865",
                      width: 30,
                      height: 30,
                     
                    }}
                  />
                </IconButton>
              </Link> */}
              <Link
                target="_blank"
                rel="noopener"
                href="https://www.instagram.com/mascotavineyards/?igshid=qkrm1xpz2usm"
              >
                {/* <div
                  // color="inherit"
                  // edge="start"
                  className={classes.arrow}
                  // style={{}}
                > */}
                  <InstagramIcon
                    style={{
                      color: "#686865",
                      width: 30,
                      height: 30,
                  
                    }}
                  />
                {/* </div> */}
              </Link>
              {/* <Link
                target="_blank"
                rel="noopener"
                href="https://www.youtube.com"
              >
                <IconButton
                  color="inherit"
                  edge="start"
                  className={classes.arrow}
                  style={{}}
                >
                  <YouTubeIcon
                    style={{
                      color: "#686865",
                      width: 30,
                      height: 30,
                     
                    }}
                  />
                </IconButton>
              </Link> */}
            </div>
          </div>
        </Grid>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography className={classes.textCondition}>
          © 2020 Mascotas Vineyards, todos los derecho reservados. Politicas de
          privacidad - Términos y condiciones
        </Typography>
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(Footer);
