import React,{useEffect} from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Portada from "../components/Portada";
import FichaVino from "../components/FichaVino";
import VysorVideo from "../components/VysorVideo";
import SlideStick from "../components/SlideStick";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // background: "rgba(117,84,67,1)",
    // background:
    //   "-moz-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-webkit-gradient(left top, right top, color-stop(0%, rgba(117,84,67,1)), color-stop(59%, rgba(151,99,69,1)), color-stop(100%, rgba(174,110,71,1)))",
    background: "#2b2b25"
    // background:
    //   "-o-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-ms-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "linear-gradient(to right, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // filter:
    //   "progid:DXImageTransform.Microsoft.gradient( startColorstr='#755443', endColorstr='#ae6e47', GradientType=1 )",
  },

  container: {
    position:"relative",
    maxWidth: 950,
    margin: "0 auto",
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },
  containerVino: {
    // display:"flex",
    textAlign: "center",
    // justifyContent:"center",
    [theme.breakpoints.up("md")]: {
      textAlign: "flex-end",
    },
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      alingItem: "flex-end",
    },
  },
  imagenVino: {
    height: 400,
    margin: "0px auto",
    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
    [theme.breakpoints.up("md")]: {
      height: 550,
      marginTop: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      height: 700,
      marginTop:"auto"
    },
  },
  mancha:{
    position:"absolute",
    top:0,
    height: 100,
    left:"50%",
    [theme.breakpoints.up("lg")]: {
      left:"70%",
      height: 300
    },
  }
}));
const Magnanime = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.root}>
       <Helmet>
      
        <title>Magn&aacute;nime Wine</title>
        <meta
          name="description"
          content="Intensely red with brick red hues, this Cabernet Sauvignon delivers delicate and complex aromas that are reminiscent of ripe red fruits and crème de cassis. A wine of impeccable character, with a round and velvety texture."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com/wines/magnanime"
        />
      </Helmet>
      <Portada url={"magnanime.jpg"} />
      <Grid container className={classes.container}>
        <img 
        className={classes.mancha}
        alt="mancha" src={require('../assets/vinos/mancha_beige.png')}/>
        <Grid item xs={12} md={6} className={classes.containerVino}>
          <img
            className={classes.imagenVino}
            alt={"vino chardonnay"}
            src={require("../assets/vinos/bottleshot_magnanime.png")}
          />
        </Grid>
        <FichaVino
          titulo={"Magnánime"}
          color={"white"}
          subtitulo={"CABERNET SAUVIGNON"}
          composition={"100% Cabernet Sauvignon"}
          apellation={"Uco Valley, Mendoza"}
          tasting={
            "Intensely red with brick red hues, this Cabernet Sauvignon delivers delicate and complex aromas that are reminiscent of ripe red fruits and crème de cassis. A wine of impeccable character, with a round and velvety texture."
          }
          puntos={"puntos_magnanime.png"}
        />
      </Grid>

      <VysorVideo titulo={"IN OPI'S WORDS"} portada={"magnanime"} vimeo={"455626581"} />

      <SlideStick tipo={6} />
    </div>
  );
};

export default Magnanime;
