import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Portada from "../components/Portada";
import clsx from "clsx";
import { Typography, Divider } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // background: "rgba(117,84,67,1)",
    // background:
    //   "-moz-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-webkit-gradient(left top, right top, color-stop(0%, rgba(117,84,67,1)), color-stop(59%, rgba(151,99,69,1)), color-stop(100%, rgba(174,110,71,1)))",
    background:
      "-webkit-linear-gradient(left, rgba(150,119,96,1) 0%, rgba(168,133,106,1) 38%, rgba(199,162,133,1) 100%)",
    // background:
    //   "-o-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-ms-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "linear-gradient(to right, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // filter:
    //   "progid:DXImageTransform.Microsoft.gradient( startColorstr='#755443', endColorstr='#ae6e47', GradientType=1 )",
  },

  container: {
    position: "relative",
    maxWidth: 950,
    margin: "0 auto",
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 100,
      paddingBottom: 70,
    },
  },
  black: { background: "#2b2b25" },

  white: {
    background:
      "-webkit-linear-gradient(left, rgba(150,119,96,1) 0%, rgba(168,133,106,1) 38%, rgba(199,162,133,1) 100%)",
  },
  space: {
    //  paddingBottom: 30,
    //   [theme.breakpoints.up("lg")]: {
    //     paddingBottom: 100,
    //   },
  },
  containerVino: {
    textAlign: "center",
    // display:"flex",
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
  },
  imagenVino: {
    height: 300,
    margin: "0px auto",
    [theme.breakpoints.up("sm")]: {
      height: 350,
    },
    [theme.breakpoints.up("md")]: {
      height: 400,
      marginTop: 40,
    },
    [theme.breakpoints.up("lg")]: {
      height: 450,
      marginTop: 50,
    },
  },
  containerBotella: {
    // textAlign:"center"
  },
  img_botella: {
    width: 260,
    // transform:"rotate(90deg)",
    // WebkitTransform:"rotate(90deg)",
    [theme.breakpoints.up("lg")]: {
      width: 350,
      marginLeft: -15,
      // marginTop:-150,
      // marginRight:70
    },
  },
  mancha: {
    position: "absolute",
    top: 0,
    height: 100,
    left: "50%",
    [theme.breakpoints.up("lg")]: {
      left: "70%",
      height: 300,
    },
  },

  //

  containerFicha: {
    textAlign: "center",
    // display: "grid",
    // alignItems: "flex-end",
    padding: "40px 25px 0 25px",
    [theme.breakpoints.up("md")]: { textAlign: "initial", padding: 0 },
  },

  margen: {
    [theme.breakpoints.up("md")]: {
      marginTop: 60,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 60,
    },
  },
  containerVinoFicha: {
    [theme.breakpoints.up("md")]: {},
  },
  titulo: {
    color: "white",
    fontFamily: "Dis-Bold",
    fontSize: 30,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 36,
      letterSpacing: 2,
    },
  },
  beige: {
    color: "#c28a6a",
  },
  subtitulo: {
    color: "#333333",
    fontFamily: "Rel-Medium",
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      letterSpacing: 3,
      marginBottom: 40,
    },
  },
  grey: { color: "grey" },
  titulo_descripcion: {
    color: "#333333",
    fontFamily: "Rel-Bold",
    fontSize: 16,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      letterSpacing: 2,
    },
  },
  parrafo_descripcion: {
    color: "#333333",
    fontFamily: "Rel-Medium",
    fontSize: 16,
    textAlign: "initial",
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
      letterSpacing: 1.1,
    },
  },
  a_white: { color: "white" },
  img_cuadrados: {
    width: 60,
    margin: "20px auto",

    [theme.breakpoints.up("md")]: {
      margin: "0",
      marginBottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      width: 70,
    },
  },
  img_puntos: {
    // width: 190,
    height: 70,
    margin: "auto",
    marginRight: 12,
    [theme.breakpoints.up("sm")]: {
      margin: "0",
      marginRight: 12,
    },
    [theme.breakpoints.up("md")]: {
      // width: 220,
      height: 70,
      marginRight: 12,
    },
    [theme.breakpoints.up("lg")]: {
      // width: 260,
      height: 70,
      marginRight: 17,
    },
  },
  divider: {
    color: "#754c24",
    // backgroundColor: "#754c24",
    margin: "10px 0",
    background:
      "linear-gradient(to right, rgba(198,172,94,1) 0%, rgba(198,172,94,1) 29%, rgba(254,246,231,1) 56%, rgba(213,186,147,1) 77%, rgba(146,93,29,1) 100%)",
    height: 2,
    [theme.breakpoints.up("lg")]: {
      margin: "15px 0",
    },
  },
  dividerSection: {
    width: "100%",
    background:
      "linear-gradient(to right, rgba(198,172,94,1) 0%, rgba(198,172,94,1) 29%, rgba(254,246,231,1) 56%, rgba(213,186,147,1) 77%, rgba(146,93,29,1) 100%)",
    height: 10,
  },
}));
const Scores = () => {
  const classes = useStyles();
  const color = "black";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.root}>
       <Helmet>
        <title>Un&aacute;nime Wines Scores</title>
        <meta
          name="description"
          content="Unique, prestigious, award-winning wines from Mendoza, Argentina."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com/scores"
        />
      </Helmet>
      <Portada url={"scores.jpg"} />
      <Divider className={classes.dividerSection} />
      <div className={clsx(classes.black, classes.space)}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={5} className={classes.containerVino}>
            <div>
              <img
                alt="cuadrados"
                className={classes.img_cuadrados}
                src={require("../assets/vinos/cuadrados_claro.png")}
              />
              <Typography className={clsx(classes.titulo, classes.beige)}>
                Unánime
              </Typography>
              <Typography className={clsx(classes.subtitulo, classes.grey)}>
                GRAN VINO TINTO
              </Typography>
            </div>
            <div className={classes.containerBotella}>
              <img
                alt="cuadrados"
                className={classes.img_botella}
                src={require("../assets/score/bottleshot_tinto.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7} className={classes.containerFicha}>
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2017
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/95_JS_gris.png`)}
            />
            <br /> <br />
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2016
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/93_TA_gris.png`)}
            />
            <br /> <br />
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2015
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/94_JS_gris.png`)}
            />
            <br /> <br />
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2014
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/93_JS_gris.png`)}
            />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/95_TA_gris.png`)}
            />
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.dividerSection} />
      <div className={classes.white}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={5} className={classes.containerVino}>
            <div>
              <img
                alt="cuadrados"
                className={classes.img_cuadrados}
                src={
                  color === "black"
                    ? require("../assets/vinos/cuadrados_oscuro.png")
                    : require("../assets/vinos/cuadrados_claro.png")
                }
              />
              <Typography className={classes.titulo}>Unánime</Typography>
              <Typography className={classes.subtitulo}>MALBEC</Typography>
            </div>
            <div className={classes.containerBotella}>
              <img
                alt="cuadrados"
                className={classes.img_botella}
                src={require("../assets/score/bottleshot_malbec.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7} className={classes.containerFicha}>
            <Typography className={classes.parrafo_descripcion}>
              2017
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/94_JS_negro.png`)}
            />
            <br />
            <br />
            <Typography className={classes.parrafo_descripcion}>
              2016
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/93_JS_negro.png`)}
            />
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.dividerSection} />
      <div className={clsx(classes.black, classes.space)}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={5} className={classes.containerVino}>
            <div>
              <img
                alt="cuadrados"
                className={classes.img_cuadrados}
                src={require("../assets/vinos/cuadrados_claro.png")}
              />
              <Typography className={clsx(classes.titulo, classes.beige)}>
                Unánime
              </Typography>
              <Typography className={clsx(classes.subtitulo, classes.grey)}>
                CHARDONNAY
              </Typography>
            </div>
            <div className={classes.containerBotella}>
              <img
                alt="cuadrados"
                className={classes.img_botella}
                src={require("../assets/score/bottleshot_chardonnay.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7} className={classes.containerFicha}>
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2019
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/93_JS_gris.png`)}
            />
            <br /> <br />
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2018
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/94_JS_gris.png`)}
            />
            <br /> <br />
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2017
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/92_JS_gris.png`)}
            />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/93_TA_gris.png`)}
            />
            <br /> <br />
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2016
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/93_JS_gris.png`)}
            />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/92_TA_gris.png`)}
            />
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.dividerSection} />
      <div className={classes.white}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={5} className={classes.containerVino}>
            <div>
              <img
                alt="cuadrados"
                className={classes.img_cuadrados}
                src={
                  color === "black"
                    ? require("../assets/vinos/cuadrados_oscuro.png")
                    : require("../assets/vinos/cuadrados_claro.png")
                }
              />
              <Typography className={classes.titulo}>Unánime</Typography>
              <Typography className={classes.subtitulo}>PINOT NOIR</Typography>
            </div>
            <div className={classes.containerBotella}>
              <img
                alt="cuadrados"
                className={classes.img_botella}
                src={require("../assets/score/bottleshot_pinot.png")}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={clsx(classes.containerFicha, classes.margen)}
          >
            <Typography className={classes.parrafo_descripcion}>
              2017
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/91_JS_negro.png`)}
            />
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.dividerSection} />
      <div className={classes.black}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={5} className={classes.containerVino}>
            <div>
              <img
                alt="cuadrados"
                className={classes.img_cuadrados}
                src={require("../assets/vinos/cuadrados_claro.png")}
              />
              <Typography className={clsx(classes.titulo, classes.beige)}>
                Unánime
              </Typography>
              <Typography className={clsx(classes.subtitulo, classes.grey)}>
                SIGNATURE
              </Typography>
            </div>
            <div className={classes.containerBotella}>
              <img
                alt="cuadrados"
                className={classes.img_botella}
                src={require("../assets/score/bottleshot_signature.png")}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={clsx(classes.containerFicha, classes.margen)}
          >
            <Typography
              className={clsx(classes.parrafo_descripcion, classes.a_white)}
            >
              2017
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/94_JS_gris.png`)}
            />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/93_TA_gris.png`)}
            />
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.dividerSection} />
      <div className={classes.white}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={5} className={classes.containerVino}>
            <div>
              <img
                alt="cuadrados"
                className={classes.img_cuadrados}
                src={
                  color === "black"
                    ? require("../assets/vinos/cuadrados_oscuro.png")
                    : require("../assets/vinos/cuadrados_claro.png")
                }
              />
              <Typography
                className={classes.titulo}
                style={{ marginBottom: 40 }}
              >
                Magnánime
              </Typography>
            </div>
            <div className={classes.containerBotella}>
              <img
                alt="cuadrados"
                className={classes.img_botella}
                src={require("../assets/score/bottleshot_magnanime.png")}
              />
            </div>

            {/* <Typography className={classes.subtitulo}>PINOT NOIR</Typography> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={clsx(classes.containerFicha, classes.margen)}
          >
            <Typography className={classes.parrafo_descripcion}>
              2013
            </Typography>
            <Divider className={classes.divider} />
            <img
              className={classes.img_puntos}
              alt={"puntos"}
              src={require(`../assets/score/95_JS_negro.png`)}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Scores;
