import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Parallax } from "react-parallax";
import Portada from "../components/Portada";

import Hidden from "@material-ui/core/Hidden";
import SectionWines from "../components/SectionWines";

const useStyles = makeStyles((theme) => ({
  opiContainer: {
    position: "relative",
    overflow: "hidden",
    // backgroundImage: `url(${require("../assets/fondo-opi.jpg")})`,
    // backgroundSize: "cover",
    backgroundSize: "auto",
    height: 400,
    // paddingTop: "10vh",
    paddingTop: 40,
    backgroundPositionX: "50%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      // paddingTop: "30vh",
      height: 550,
      backgroundSize: "auto",
    },

    [theme.breakpoints.up("lg")]: {
      // paddingTop: "30vh",
      height: 550,
      backgroundSize: "auto",
    },
    [theme.breakpoints.up("xl")]: {
      // paddingTop: "30vh",
      height: 670,
      backgroundSize: "auto",
    },
  },
  opiBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    minHeight: "100%",
    minWidth: "100%",
    zIndex: 0,
  },
  opiTextos: {
    maxWidth: 550,
    padding: 10,
    margin: "0 auto",
    zIndex: 10,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "5%",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "5%",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 0,
      maxWidth: 620,
    },
  },
  opiTextosMobile: {
    maxWidth: 550,
    padding: 10,
    margin: "0 auto",
    zIndex: 10,
    textAlign: "center",
    position: "relative",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  opiTitulo: {
    fontFamily: "Dis-Regular",
    fontSize: 16,
    color: "#ffffff",
    [theme.breakpoints.up("sm")]: {
      fontSize: 27,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 27,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 27,
      letterSpacing: 1.2,
    },
  },
  opiSubtitulo: {
    fontFamily: "Dis-Black",
    color: "#ffffff",
    lineHeight: "50px",
    marginBottom: 10,
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      fontSize: 26,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 26,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 26,
      letterSpacing: 1.2,
    },
  },
  opiParrafo: {
    fontFamily: "Rel-Regular",
    color: "#a1a1a1",
    lineHeight: "25px",
    textAlign: "center",
    fontSize: 13,
    padding: "0 20px",
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("lg")]: {
      lineHeight: "34px",
      fontSize: 15,
      letterSpacing: 1.1,
    },
  },

  opiFirma: {
    width: 200,
    [theme.breakpoints.up("md")]: {
      width: 300,
    },
  },
  opiMeet: {
    background: "transparent",
    border: "1px solid #d59570",
    borderRadius: 0,
    fontFamily: "Rel-Regular",
    padding: "10px 20px",
    margin: "15px 0",
    MozTransition: "all 1s ease 0s",
    OTransition: "all 1s ease 0s",
    msTransition: "all 1s ease 0s",
    WebkitTransition: "all 1s ease 0s",
    letterSpacing: 1,
    "&:hover": {
      background: "#d59570",
      color: "black",
    },
    fontSize: 13,
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      padding: "10px 20px",
      margin: "15px 0",
      fontSize: 15,
    },
    [theme.breakpoints.up("lg")]: {
      lineHeight: "34px",
      fontSize: 15,
      letterSpacing: 2,
    },
  },

  origenContainer: {
    position: "relative",
    overflow: "hidden",
    // backgroundImage: `url(${require("../assets/fondo-finca.jpg")})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // backgroundSize: "auto",
    height: 550,
    padding: 20,
    paddingTop: "10vh",
    backgroundPositionX: "50%",
    display: "flex",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 100,
      height: 600,
      backgroundSize: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 200,
      height: 750,
      backgroundSize: "auto 100%",
      // backgroundPositionX: "0px",
    },
  },
  origenBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    minHeight: "100%",
    minWidth: "100%",
    zIndex: 0,
  },
  origenContainerCuadro: {
    position: "relative",
    width: "100%",
    display: "flex",
    height: 470,
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      width: 500,
      height: 400,
      padding: "50px 20px",
      marginLeft: "auto",
      marginRight: "20%",
    },
    [theme.breakpoints.up("lg")]: {
      width: 550,
      height: 400,
      padding: "50px 20px",
      marginLeft: "auto",
      marginRight: 0,
    },
  },
  cuadro: {
    top: 0,
    right: 0,
    position: "absolute",
    background: "transparent",
    height: "100%",
    width: "75%",
    borderTop: "1px solid white",
    borderRight: "1px solid white",
    borderBottom: "1px solid white",
  },
  cuadroTop: {
    borderLeft: "1px solid white",
    height: 30,
    position: "absolute",
    top: 0,
    left: 0,
  },
  cuadroBottom: {
    borderLeft: "1px solid white",
    height: 30,
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  origenTextos: { textAlign: "initial", padding: 10 },
  origenTitulo: {
    cursor: "pointer",
    textShadow: "-1px 1px 0 black",
    fontFamily: "Rel-Medium",
    fontSize: 20,
    color: "#d59570",

    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 30,
      letterSpacing: 1.2,
    },
  },
  origenSubtitulo: {
    textShadow: "-1px 1px 0 black",
    fontFamily: "Dis-Bold",
    color: "#ffffff",
    lineHeight: "50px",
    marginBottom: 10,
    fontSize: 23,
    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 30,
      letterSpacing: 1.2,
    },
  },
  origenParrafo: {
    textShadow: "-1px 1px 0 black",
    fontFamily: "Rel-Regular",
    color: "#ffffff",
    lineHeight: "25px",
    fontSize: 13,
    textAlign: "justify",
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("lg")]: {
      lineHeight: "34px",
      fontSize: 15,
      letterSpacing: 1.1,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    }
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
const Home = (props) => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
     <Helmet>
        
        <title>Un&aacute;nime Wines</title>
        <meta
          name="description"
          content="Unique, prestigious, award-winning wines from Mendoza, Argentina."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com"
        />
      </Helmet>
      <Portada url={"home.jpg"} />
      <Hidden smUp>
        <Parallax
          bgImage={require("../assets/fondo-opi-mobile.jpg")}
          strength={500}
          renderLayer={(percentage) => <div></div>}
        >
          <div style={{ maxWidth: 500, height: 950, zIndex: 5 }}>
            <div className={classes.opiTextosMobile}>
              <div style={{ position: "absolute", top: 20 }}>
                <Typography className={classes.opiTitulo}>
                WHEN OUR WINES REACH PERFECTION,
                </Typography>
                <Typography className={classes.opiSubtitulo}>
                WE KNOW IT'S TIME TO SHARE THEM
                </Typography>
                <Typography className={classes.opiParrafo}>
                  Unánime wines seek complete harmony and excellence, with an
                  unbeatable quality reflected in each and every bottle. When
                  this vision is shared by our team of winemakers, and endorsed
                  by critics and consumers, the decision is Unanimous (Unánime).
                </Typography>
              </div>
              <div style={{ position: "absolute", bottom: 20 }}>
                <div>
                  <img
                    className={classes.opiFirma}
                    alt={"firma opi"}
                    src={require("../assets/firma-opi.png")}
                  />
                </div>
                <Link className={classes.link} to={"/winemaker"} component={RouterLink}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                 
                  className={classes.opiMeet}
                >
                  MEET OPI
                </Button>
                </Link>
              </div>
            </div>
          </div>
        </Parallax>
      </Hidden>
      <Hidden xsDown>
        <Parallax
          bgImage={require("../assets/fondo-opi.jpg")}
          // strength={500}
          strength={200}
          renderLayer={(percentage) => <div></div>}
        >
          <div className={classes.opiContainer}>
            {/* <img
          className={classes.opiBackground}
          alt="background origen"
          src={require("../assets/fondo-opi.jpg")}
        /> */}
            <div
              style={{
                width: "100%",
                maxWidth: 1300,
                zIndex: 5,
                margin: "auto",
              }}
            >
              <div className={classes.opiTextos}>
                <Typography className={classes.opiTitulo}>
                  {/* CUANDO LOS VINOS SON INMEJORABLES
                  We decided, when unbeatable, */}
                  WHEN OUR WINES REACH PERFECTION,
                </Typography>
                <Typography className={classes.opiSubtitulo}>
                  {/* DECIDIMOS COMPARTIRLOS
                  wines are to be shared */}
                  WE KNOW IT'S TIME TO SHARE THEM
                </Typography>
                <Typography className={classes.opiParrafo}>
                  {/* Unánime nace de la convicción de hacer vinos de completa
                  armonía y excelencia, de calidad inmejorable reflejada en cada
                  botella. Cuando esa visión es compartida por un equipo de
                  trabajo, consensuada por la crítica y los consumidores, la
                  decisión es Unánime. */}
                  Unánime wines seek complete harmony and excellence, with an
                  unbeatable quality reflected in each and every bottle. When
                  this vision is shared by our team of winemakers, and endorsed
                  by critics and consumers, the decision is Unanimous (Unánime).
                </Typography>

                <div>
                  <img
                    className={classes.opiFirma}
                    alt={"firma opi"}
                    src={require("../assets/firma-opi.png")}
                  />
                </div>
                <Link className={classes.link} to={"/winemaker"} component={RouterLink}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                
                  className={classes.opiMeet}
                >
                  MEET OPI
                </Button>
                </Link>
              </div>
            </div>
          </div>
        </Parallax>
      </Hidden>

      <SectionWines />

      <Hidden smUp>
        <Parallax
          bgImage={require("../assets/fondo-finca-mobile.jpg")}
          strength={200}
                renderLayer={(percentage) => <div>{" "}</div>}
        >
      <div className={classes.origenContainer}>
        {/* <picture>
          <source
            type="image/jpeg"
            srcSet={require("../assets/fondo-finca-mobile.jpg")}
            media="(max-width: 575px)"
          />
          <source
            type="image/jpeg"
            srcSet={require("../assets/fondo-finca.jpg")}
            media="(min-width: 576px)"
          />
          <img
            className={classes.origenBackground}
            alt="background origen"
            // src={require("../assets/fondo-finca.jpg")}
          />
        </picture> */}

        <div className={classes.origenContainerCuadro}>
          <div className={classes.cuadro}>
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <div className={classes.cuadroTop}></div>
              <div className={classes.cuadroBottom}></div>
            </div>
          </div>
          <div className={classes.origenTextos}>
          <Link className={classes.link} to={"/origin"} component={RouterLink}>
            <Typography
              className={classes.origenTitulo}
           
            >
              OUR ORIGIN
            </Typography>
            </Link>
            <Typography className={classes.origenSubtitulo}>
              UCO VALLEY, MENDOZA <br />
              ARGENTINA
            </Typography>
            <Typography className={classes.origenParrafo}>
              {/* Nuestros viñedos se encuentran en una de las regiones
              vitivinícolas mundiales con mayor desarrollo de los últimos 20
              años. El clima seco, el suelo aluvional, la altura y la amplitud
              térmica favorecen la maduración y la concentración de taninos,
              conformando un lugar donde los viñedos se expresan al máximo,
              regados por el agua cercana de los Andes. */}
              Our vineyards are located in one of the world's most exciting wine
              regions to be developed in the last 20 years. High altitudes, dry
              weather, alluvial soil, thermal amplitude, and meltwater from the
              Andes Mountains combine to create unique mountain desert terroirs.
              This special place gives life to our Unanime wines, with ripe
              tannins, concentrated flavors and rich personality.
            </Typography>
          </div>
        </div>
    
    
      </div>
      </Parallax>
      </Hidden>
      <Hidden xsDown>
        <Parallax
          bgImage={require("../assets/fondo-finca.jpg")}
          // strength={500}
          strength={200}
          renderLayer={(percentage) => <div></div>}
        >
         
            <div
              style={{
                width: "100%",
                maxWidth: 1300,
                zIndex: 5,
                margin: "auto",
              }}
            >
                 <div className={classes.origenContainer}>
        {/* <picture>
          <source
            type="image/jpeg"
            srcSet={require("../assets/fondo-finca-mobile.jpg")}
            media="(max-width: 575px)"
          />
          <source
            type="image/jpeg"
            srcSet={require("../assets/fondo-finca.jpg")}
            media="(min-width: 576px)"
          />
          <img
            className={classes.origenBackground}
            alt="background origen"
            // src={require("../assets/fondo-finca.jpg")}
          />
        </picture> */}

        <div className={classes.origenContainerCuadro}>
          <div className={classes.cuadro}>
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <div className={classes.cuadroTop}></div>
              <div className={classes.cuadroBottom}></div>
            </div>
          </div>
          <div className={classes.origenTextos}>
          <Link className={classes.link} to={"/origin"} component={RouterLink}>
            <Typography
              className={classes.origenTitulo}
             
            >
              OUR ORIGIN
            </Typography>
            </Link>
            <Typography className={classes.origenSubtitulo}>
              UCO VALLEY, MENDOZA <br />
              ARGENTINA
            </Typography>
            <Typography className={classes.origenParrafo}>
              {/* Nuestros viñedos se encuentran en una de las regiones
              vitivinícolas mundiales con mayor desarrollo de los últimos 20
              años. El clima seco, el suelo aluvional, la altura y la amplitud
              térmica favorecen la maduración y la concentración de taninos,
              conformando un lugar donde los viñedos se expresan al máximo,
              regados por el agua cercana de los Andes. */}
              Our vineyards are located in one of the world's most exciting wine
              regions to be developed in the last 20 years. High altitudes, dry
              weather, alluvial soil, thermal amplitude, and meltwater from the
              Andes Mountains combine to create unique mountain desert terroirs.
              This special place gives life to our Unanime wines, with ripe
              tannins, concentrated flavors and rich personality.
            </Typography>
          </div>
        </div>
    
    
      </div>
      </div>
      </Parallax>
      </Hidden>
    </div>
  );
};

Home.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(Home);
