import React, { useRef } from "react";
import { Helmet } from "react-helmet";
// import Portada from "../components/Portada";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import { Link } from "@material-ui/core";
const arrayImage = [
  {
    image: require("../assets/findus/mascota_shop.jpg"),
    url: "https://www.mascotavineyards.com.ar/unanime-gran-vino-tinto.html",
  },
  {
    image: require("../assets/findus/total_wine.jpg"),
    url:
      "https://www.totalwine.com/wine/red-wine/cabernet-sauvignon/mascota-vineyards-unanime/p/130007750",
  },
];
const useStylesZoom = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  container: {
    zIndex: 10,
    border: "1px solid #d59570",
    position: "relative",
    width: 200,
    height: 200,
    margin: "5px auto",
    textAlign: "center",
    backgroundColor: "transparent",

    [theme.breakpoints.up("sm")]: {
      width: 250,
      height: 250,
      margin: "0 auto",
    },

    [theme.breakpoints.up("lg")]: {
      width: 300,
      height: 300,
    },
  },
  cuadro: {
    cursor: "pointer",
    zIndex: 30,
    position: "absolute",
    overflow: "hidden",
    // border: "1px solid #d59570",

    width: 185,
    height: 185,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    textAlign: "center",
    backgroundColor: "transport",

    [theme.breakpoints.up("sm")]: {
      width: 235,
      height: 235,
    },

    [theme.breakpoints.up("lg")]: {
      width: 285,
      height: 285,
    },
  },
  zoom: {
    zIndex: 20,
    position: "absolute",
    transform: "scale(1.1)",
    // cursor: "pointer",
    width: 185,
    height: 185,
    transition: "all 1s ease 0s",
    // transform: "scale(1.4)",
    // "&:hover": {
    //   transform: "scale(1.2)",
    // },
    [theme.breakpoints.up("sm")]: {
      width: 240,
      height: 240,
    },

    [theme.breakpoints.up("lg")]: {
      width: 290,
      height: 290,
    },
  },
}));
const ZoomInHome = ({ image, url }) => {
  const classes = useStylesZoom();

  const zoom = useRef(null);
  const zoomIn = () => {
    zoom.current.style.webkitTransform = "scale(1)";
  };
  const zoomOut = (posicion) => {
    zoom.current.style.webkitTransform = "scale(1.1)";
  };
  return (
    <div className={classes.container}>
      <div
        className={classes.cuadro}
        onMouseEnter={zoomIn}
        onMouseLeave={zoomOut}
      >
        <Link target="_blank" rel="noopener" href={url} style={{ zIndex: 10 }}>
          <div ref={zoom} className={classes.zoom}>
            <img style={{ width: "100%" }} alt={"vino-url"} src={image} />
          </div>
        </Link>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  vinosContainer: {
    textAlign: "center",
    padding: "65px 20px 50px",
    background: `url(${require("../assets/slide/fondo-grafito.jpg")})`,
    backgroundSize:"contain",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 60px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "110px 30px 70px",
        backgroundSize:"cover",
      },
    [theme.breakpoints.up("lg")]: {
      padding: "130px 50px 80px",
 
    },
  },
  containerData: {
    textAlign: "center",
    maxWidth: 600,
    margin: "0 auto",
  
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
      maxWidth: 600,
    },
    [theme.breakpoints.up("lg")]: {
     
        maxWidth: 650,
      },
  },
  vinosTitulo: {
    fontFamily: "Dis-Bold",
    fontSize: 23,
    color: "#c28a6a",
    letterSpacing: 3,
    marginBottom: 20,
    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 36,
      letterSpacing: 2,
    },
  },
  vinosParrafo: {
    fontFamily: "Rel-Regular",
    color: "#a1a1a1",
    fontSize: 13,
    marginBottom: 10,
    [theme.breakpoints.up("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.up("lg")]: {
      lineHeight: "34px",
      fontSize: 15,
      letterSpacing: 1.1,
    },
  },
  img_cuadrados: {
    width: 60,
    margin: "20px auto",

    [theme.breakpoints.up("md")]: {
      margin: "0",
      marginBottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      width: 70,
    },
  },
  vinosZoom: {
    margin: "5px 0",
    // [theme.breakpoints.up("sm")]: {
    //   margin: "15px 0",
    // },
    [theme.breakpoints.up("md")]: {
      margin: "10px 0",
    },
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
const FindUs = () => {
  const classes = useStyles();
  return (
    <div>
      <Helmet>
        <title>Un&aacute;nime - Find Us</title>
        <meta
          name="description"
          content="Unique, prestigious, award-winning wines from Mendoza, Argentina."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com/find_us"
        />
      </Helmet>
      {/* <Portada url={"unanime.jpg"} /> */}
      <div className={classes.vinosContainer}>
        <div className={classes.containerData}>
          <img
            alt="cuadrados"
            className={classes.img_cuadrados}
            src={require("../assets/vinos/cuadrados_claro.png")}
          />
          <Typography className={classes.vinosTitulo}>
            Find Our Wines
          </Typography>
          <Typography className={classes.vinosParrafo}>
            See stores and shipping locations
          </Typography>
        </div>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ maxWidth: 700, margin: "0 auto" }}
        >
              <Grid item xs={12} sm={6} className={classes.vinosZoom}>
            <ZoomInHome image={arrayImage[1].image} url={arrayImage[1].url} />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.vinosZoom}>
            <ZoomInHome image={arrayImage[0].image} url={arrayImage[0].url} />
          </Grid>
        
        </Grid>
      </div>
    </div>
  );
};

export default FindUs;
