import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import Submenu from "./Submenu";
import ButtonHover from "./ButtonHover";
const drawerWidth = 240;
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1075,
      lg: 1280,
      xl: 1920,
    },
  },
});
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    padding: "10px 0 5px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "transparent",
    // background: "rgba(32,22,19,0.88)",
    // background:
    //   "-moz-linear-gradient(top, rgba(32,22,19,0.88) 0%, rgba(32,22,19,0.57) 54%, rgba(32,22,19,0.31) 100%)",
    // background:
    //   "-webkit-gradient(left top, left bottom, color-stop(0%, rgba(32,22,19,0.88)), color-stop(54%, rgba(32,22,19,0.57)), color-stop(100%, rgba(32,22,19,0.31)))",
    background:
      "-webkit-linear-gradient(top, rgba(32,22,19,0.88) 0%, rgba(32,22,19,0.57) 54%, rgba(32,22,19,0.31) 100%)",
    // background:
    //   "-o-linear-gradient(top, rgba(32,22,19,0.88) 0%, rgba(32,22,19,0.57) 54%, rgba(32,22,19,0.31) 100%)",
    // background:
    //   "-ms-linear-gradient(top, rgba(32,22,19,0.88) 0%, rgba(32,22,19,0.57) 54%, rgba(32,22,19,0.31) 100%)",
    // background:
    //   "linear-gradient(to bottom, rgba(32,22,19,0.88) 0%, rgba(32,22,19,0.57) 54%, rgba(32,22,19,0.31) 100%)",
    // filter:
    //   "progid:DXImageTransform.Microsoft.gradient( startColorstr='#201613', endColorstr='#201613', GradientType=0 )",
  },

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    zIndex: 20,
    padding: 0,
    margin: "auto 10px",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "rgba(32, 22, 19, 0.95)",
  },
  drawerHeader: {
    height: 75,
    position: "relative",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  content2: {
    flexGrow: 1,
    backgroundColor: "#e6eaeb",
    // padding: "10px 8px 8px 8px",
    padding: 0,
    [theme.breakpoints.up("md")]: {},
  },
  toolbar: {
    height: 60,
    [theme.breakpoints.up("md")]: {
      padding: "0 10px",
      height: 80,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "7px 20px",
      width: 1280,
      margin: "auto",
    },
    [theme.breakpoints.up("xl")]: {},
  },
  containerLogo: {
    cursor: "pointer",
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      // margin: "0 auto",
    },
  },
  logoMascota: {
    width: 50,
    [theme.breakpoints.up("md")]: {
      width: 100,
    },
  },
  logoUnanime: {
    transition: "all 1s ease 1s",
    width: 120,
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  logoUnanimeDrawer: {
    width: 90,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  texto: {
    textShadow: "-1px 1px 0 black",
    fontSize: 13,
    color: "white",
    padding: 5,
    fontFamily: "Rel-Extra-Bold",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      padding: 5,
      paddingLeft: 5,
      fontSize: 13,
      margin: "0 4px",
      letterSpacing: 1.5,
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 10px",
    },
  },
  itemTexto: {
    "&:hover": {
      backgroundColor: "black",
      color: "#d59570",
    },
  },
  subTexto: {
    textShadow: "-1px 1px 0 black",
    fontSize: 12,
    color: "white",
    padding: 5,
    fontFamily: "Rel-Regular",
    cursor: "pointer",
    "&:hover": {
      color: "#d59570",
    },
    [theme.breakpoints.up("md")]: {
      padding: 7,
      paddingLeft: 7,
      fontSize: 12,
    },
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    console.log("abrio");
    setOpen(true);
  };
  const handleDrawerClose = () => {
    console.log("cerro");
    setOpen(false);
  };

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Grid
          item
          xs={12}
          style={{
            width: "100%",
            top: 0,
            position: "absolute",
            textAlign: "center",
            background:
              "linear-gradient(to right, rgba(198,172,94,1) 0%, rgba(198,172,94,1) 29%, rgba(254,246,231,1) 56%, rgba(213,186,147,1) 77%, rgba(146,93,29,1) 100%)",
            height: 7,
          }}
        ></Grid>
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ textAlign: "center" }}
          >
            <nav>
              <Link className={classes.link} to={"/"} component={RouterLink}>
                <Button
                //  onClick={() => history.push("/")}
                >
                  <img
                    className={classes.logoMascota}
                    src={require("../assets/logo_mascota.png")}
                    alt="logo mascota"
                  />
                </Button>
              </Link>
            </nav>

            {!open && (
              <Link
                className={classes.link}
                to={"/"}
                component={RouterLink}
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  minHeight: 48,
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                // onClick={() => history.push("/")}
                >
                  <img
                    className={classes.logoUnanime}
                    src={require("../assets/logo_unanime.png")}
                    alt="UNANIME"
                  />
                </Button>
              </Link>
            )}

            {/* <div className={clsx(classes.grow, classes.sectionDesktop)} /> */}
            <div className={classes.sectionDesktop} style={{ zIndex: 20 }}>
              <div className={classes.sectionDesktop}>
                {/* <ButtonHover url={"unanime"} nombre={"UNÁNIME"}/> */}
                <Submenu />
                <ButtonHover url={"winemaker"} nombre={"WINEMAKER"} />
                <ButtonHover url={"origin"} nombre={"ORIGIN"} />
                <ButtonHover url={"scores"} nombre={"SCORES"} />
                <ButtonHover url={"find_us"} nombre={"FIND US"} />
              </div>
            </div>
          </Grid>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(
                classes.menuButton,
                open && classes.hide,
                classes.sectionMobile
              )}
            >
              <img
                style={{ height: 30, width: 30 }}
                alt={"menu icon"}
                src={require("../assets/menu_open.png")}
              />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        // anchor="left"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Link
            to={"/"}
            tag={RouterLink}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              margin: "auto",
              zIndex: 10,
            }}
            className={classes.containerLogo}
          >
            <img
              alt="logo unanime"
              className={classes.logoUnanimeDrawer}
              src={require("../assets/logo_unanime.png")}
            />
          </Link>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            edge="end"
            className={clsx(
              classes.menuButton,
              open && classes.hide,
              classes.sectionMobile
            )}
          >
            <img
              style={{ height: 30, width: 30 }}
              alt={"menu icon"}
              src={require("../assets/menu_close.png")}
            />
          </IconButton>
        </div>
        {/* <Divider />
        <List>
          <ListItem
            // style={{ paddingTop: 0, paddingBottom: 0 }}
            button
            className={classes.itemTexto}
            onClick={() => {
              handleDrawerClose();
              history.push("/wines");
              window.scrollTo(0, 0);
            }}
          >
            <Typography className={clsx(classes.texto,classes.itemTexto)}>UNÁNIME</Typography>
          </ListItem>
        </List> */}
        <Divider />
        <List>
          <Link className={classes.link} to={"/wines"} component={RouterLink}>
            <ListItem
              onClick={() => {
                handleDrawerClose();
                // history.push("/wines");
                window.scrollTo(0, 0);
              }}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
            >
              <Typography
                className={classes.texto}
                style={{ cursor: "inerith" }}
              >
                WINES
              </Typography>
            </ListItem>
          </Link>
          <Link
            className={classes.link}
            to={"/wines/magnanime"}
            component={RouterLink}
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/wines/magnanime");
              }}
            >
              <Typography className={classes.subTexto}>MAGNÁNIME</Typography>
            </ListItem>
          </Link>
          <Link
            className={classes.link}
            to={"/wines/unanime_signature"}
            component={RouterLink}
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/wines/unanime_signature");
              }}
            >
              <Typography className={classes.subTexto}>
                UNÁNIME SIGNATURE
              </Typography>
            </ListItem>
          </Link>
          <Link
            className={classes.link}
            to={"/wines/gran_vino_tinto"}
            component={RouterLink}
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/wines/gran_vino_tinto");
              }}
            >
              <Typography className={classes.subTexto}>
                GRAN VINO TINTO
              </Typography>
            </ListItem>
          </Link>
          <Link
            className={classes.link}
            to={"/wines/unanime_malbec"}
            component={RouterLink}
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/wines/unanime_malbec");
              }}
            >
              <Typography className={classes.subTexto}>
                UNÁNIME MALBEC
              </Typography>
            </ListItem>
          </Link>
          <Link
            className={classes.link}
            to={"/wines/unanime_pinot_noir"}
            component={RouterLink}
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/wines/unanime_pinot_noir");
              }}
            >
              <Typography className={classes.subTexto}>
                UNÁNIME PINOT NOIR
              </Typography>
            </ListItem>
          </Link>
          <Link
            className={classes.link}
            to={"/wines/unanime_chardonnay"}
            component={RouterLink}
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/wines/unanime_chardonnay");
              }}
            >
              <Typography className={classes.subTexto}>
                UNÁNIME CHARDONNAY
              </Typography>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link
            className={classes.link}
            to={"/winemaker"}
            component={RouterLink}
          >
            <ListItem
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/winemaker");
              }}
            >
              <Typography className={clsx(classes.texto, classes.itemTexto)}>
                WINEMAKER
              </Typography>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link className={classes.link} to={"/origin"} component={RouterLink}>
            <ListItem
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/origin");
              }}
            >
              <Typography className={clsx(classes.texto, classes.itemTexto)}>
                ORIGIN
              </Typography>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link className={classes.link} to={"/scores"} component={RouterLink}>
            <ListItem
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/scores");
              }}
            >
              <Typography className={clsx(classes.texto, classes.itemTexto)}>
                SCORES
              </Typography>
            </ListItem>
          </Link>
        </List>

        <Divider />
        <List>
          <Link className={classes.link} to={"/find_us"} component={RouterLink}>
            <ListItem
              button
              className={classes.itemTexto}
              onClick={() => {
                handleDrawerClose();
                // history.push("/find_us");
              }}
            >
              <Typography className={clsx(classes.texto, classes.itemTexto)}>
                FIND US
              </Typography>
            </ListItem>
          </Link>
        </List>

        <Divider />
      </Drawer>
      <main className={classes.content2}>
        {/* <Toolbar id="back-to-top-anchor" /> */}

        {props.children}
      </main>

      <Footer />
    </React.Fragment>
  );
};

Header.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(Header);
