import React from "react";
import {  BrowserRouter, Switch,  Redirect } from "react-router-dom";
import RouteWithHeader from "./components/RouteWithHeader";
// import Productos from "./views/Productos";
// import Nosotros from "./views/Nosotros";
import Home from "./views/Home";
import Chardonnay from "./views/Chardonnay";
import Magnanime from "./views/Magnanime";
import Signature from "./views/Signature";
import Tinto from "./views/Tinto";
import Malbec from "./views/Malbec";
import Pinot from "./views/Pinot";
import Unanime from "./views/Unanime";
import Winemaker from "./views/Winemaker";
import Origin from "./views/Origin";
import Scores from "./views/Scores";
import FindUs from "./views/FindUs";
// import  Terminos  from "./views/Terminos";



const Routes = () => {

  return (
    <BrowserRouter>
      <Switch>
      <RouteWithHeader exact path="/" component={Home} />
      <RouteWithHeader exact path="/wines" component={Unanime} />
      <RouteWithHeader exact path="/scores" component={Scores} />
      <RouteWithHeader exact path="/winemaker" component={Winemaker} />
      <RouteWithHeader exact path="/origin" component={Origin} />
      <RouteWithHeader exact path="/wines/unanime_chardonnay" component={Chardonnay} />
      <RouteWithHeader exact path="/wines/magnanime" component={Magnanime} />
      <RouteWithHeader exact path="/wines/unanime_signature" component={Signature} />
      <RouteWithHeader exact path="/wines/gran_vino_tinto" component={Tinto} />
      <RouteWithHeader exact path="/wines/unanime_malbec" component={Malbec} />
      <RouteWithHeader exact path="/wines/unanime_pinot_noir" component={Pinot} />
      <RouteWithHeader exact path="/find_us" component={FindUs} />
        <Redirect to="/" />
        
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;


