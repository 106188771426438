import React from 'react'
import { Helmet } from "react-helmet";
import Portada from '../components/Portada'
import SectionWines from '../components/SectionWines'

const Unanime = () => {
    return (
        <div>
             <Helmet>
        
        <title>Un&aacute;nime Wines</title>
        <meta
          name="description"
          content="Unique, prestigious, award-winning wines from Mendoza, Argentina."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com/unanime"
        />
      </Helmet>
              <Portada url={"unanime.jpg"} />
              <SectionWines/>
        </div>
    )
}

export default Unanime
