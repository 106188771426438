import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Portada from "../components/Portada";
// import FichaVino from "../components/FichaVino";
import { Typography, Divider } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // background: "rgba(117,84,67,1)",
    // background:
    //   "-moz-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-webkit-gradient(left top, right top, color-stop(0%, rgba(117,84,67,1)), color-stop(59%, rgba(151,99,69,1)), color-stop(100%, rgba(174,110,71,1)))",
    background:
      "-webkit-linear-gradient(left, rgba(150,119,96,1) 0%, rgba(168,133,106,1) 38%, rgba(199,162,133,1) 100%)",
    // background:
    //   "-o-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-ms-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "linear-gradient(to right, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // filter:
    //   "progid:DXImageTransform.Microsoft.gradient( startColorstr='#755443', endColorstr='#ae6e47', GradientType=1 )",
  },

  container: {
    position: "relative",
    maxWidth: 950,
    margin: "0 auto",
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },
  containerVino: {
    // display:"flex",
    textAlign: "center",
    // justifyContent:"center",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      alingItem: "flex-end",
    },
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      alingItem: "flex-end",
    },
  },
  imagenVino: {
    height: 300,
    margin: "0px auto",
    [theme.breakpoints.up("sm")]: {
      height: 350,
    },
    [theme.breakpoints.up("md")]: {
      height: 400,
      marginTop: 40,
    },
    [theme.breakpoints.up("lg")]: {
      height: 450,
      marginTop: 50,
    },
  },
  mancha: {
    position: "absolute",
    top: 0,
    height: 100,
    left: "50%",
    [theme.breakpoints.up("lg")]: {
      left: "70%",
      height: 300,
    },
  },

  //

  containerFicha: {
    textAlign: "initial",
    display: "grid",
    alignItems: "flex-end",
    padding: "40px 25px 0 25px",
  },
  containerVinoFicha: {
    [theme.breakpoints.up("md")]: {},
  },
  titulo: {
    color: "white",
    fontFamily: "Dis-Bold",
    fontSize: 30,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 36,
      letterSpacing: 2,
    },
  },
  subtitulo: {
    color: "#333333",
    fontFamily: "Rel-Medium",
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      letterSpacing: 3,
      marginBottom: 40,
    },
  },
  titulo_descripcion: {
    color: "#333333",
    fontFamily: "Rel-Bold",
    fontSize: 16,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      letterSpacing: 2,
    },
  },
  parrafo_descripcion: {
    color: "#333333",
    fontFamily: "Rel-Medium",
    fontSize: 14,
    [theme.breakpoints.up("lg")]: {
      fontSize: 15,
      letterSpacing: 1.1,
    },
  },

  img_cuadrados: {
    width: 60,
    margin: "20px auto",

    [theme.breakpoints.up("md")]: {
      margin: "0",
      marginBottom: 40,
    },
    [theme.breakpoints.up("lg")]: {
      width: 90,
    },
  },
  divider: {
    color: "#754c24",
    backgroundColor: "#754c24",
    margin: "10px 0",
    [theme.breakpoints.up("lg")]: {
      margin: "20px 0",
    },
  },
}));
const Winemaker = () => {
  const classes = useStyles();
  const color = "black";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.root}>
      <Helmet>
       
        <title>Rodolfo Opi Sadler. Winemaker</title>
        <meta
          name="description"
          content="Rodolfo 'Opi' Sadler comes from a wine making family. His memory of entering the cellar in his grandfather's basement blends the sense of adventure with the warmth of family protection."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com/winemaker"
        />
      </Helmet>
      <Portada url={"winemaker.jpg"} />
      <Grid container className={classes.container}>
        <img
          className={classes.mancha}
          alt="mancha"
          src={require("../assets/vinos/mancha_beige.png")}
        />
        <Grid item xs={12} md={6} className={classes.containerVino}>
          <img
            className={classes.imagenVino}
            alt={"vino chardonnay"}
            src={require("../assets/winemaker_opi.png")}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.containerFicha}>
          <img
            alt="cuadrados"
            className={classes.img_cuadrados}
            src={
              color === "black"
                ? require("../assets/vinos/cuadrados_oscuro.png")
                : require("../assets/vinos/cuadrados_claro.png")
            }
          />
          <Typography className={classes.titulo}>Rodolfo Opi Sadler</Typography>
          <Typography className={classes.subtitulo}>
            MASTER WINEMAKER
          </Typography>

          <Divider className={classes.divider} />

          <Typography className={classes.parrafo_descripcion}>
            {/* Rodolfo “Opi” Sadler proviene de una familia de vitivinicultores. Su
            recuerdo ingresando a la cava del sótano de su abuelo conjuga la
            sensación de aventura con el sentimiento de protección familiar. */}
            Rodolfo "Opi" Sadler comes from a wine making family. His memory of
            entering the cellar in his grandfather's basement blends the sense
            of adventure with the warmth of family protection.
          </Typography>
          <br />

          <Typography className={classes.parrafo_descripcion}>
            {/* Referente de la vitivinicultura argentina a nivel mundial, su gran
            diferencia es haberse especializado en Cabernet Sauvignon en un país
            reconocido por la calidad de su Malbec. Esto le ha permitido
            conseguir grandes logros a lo largo de sus 30 años de carrera,
            incluso en mercados históricamente vinculados al famoso “tinto
            universal”, al punto de llevar a su su vino La Mascota Cabernet
            Sauvignon 2016 a ser consagrado como el Mejor Vino Tinto del Mundo
            en Vinalies Internationales 2018 (Francia). */}
            An Argentine winemaking pioneer, Opi´s great distinction is his
            expertise in Cabernet Sauvignon in a country renowned for the
            quality of its Malbec. This allowed him to achieve great success
            throughout his 30-year career, even in markets historically linked
            with the famous "universal red". In 2018, Opi´s La Mascota Cabernet
            Sauvignon 2016 was named World's Best Red Wine in the French
            competition Vinalies Internationales.
          </Typography>
          <br />
          <Typography
            className={classes.parrafo_descripcion}
            style={{ fontFamily: "Dis-Italic" }}
          >
            {/* Unánime es actualmente el vino argentino de alta gama más vendido en
            Estados Unidos, y su vino Unánime Signature es un tributo al
            Cabernet Sauvignon, según su propia definición “la uva que capturó
            mi corazón”. */}
            Unánime is currently the top-selling high-end Argentine wine in the
            United States, and Opi´s Unánime Signature wine is a tribute to
            Cabernet Sauvignon, the first grape he worked with as a young
            winemaker, and in his own words "the grape that stole my heart".
          </Typography>
          <br />
          <Typography className={classes.parrafo_descripcion}>
            {/* Opi crea sus vinos con la emoción de transmitir sus vivencias y la
            idea de que cada instante compartido es un momento para preservar,
            realizando un trabajo que abre nuevas puertas para la consideración
            de los vinos argentinos en el mundo. */}
            Opi creates his wines with the intention of passing on his
            experiences, and the idea that each shared moment is worth
            conserving and celebrating.
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Winemaker;
