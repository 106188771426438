import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Divider } from "@material-ui/core";

const FichaVino = ({
  titulo,
  subtitulo,
  composition,
  apellation,
  tasting,
  puntos,
  color,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    container: {
      textAlign: "initial",
      display: "grid",
      alignItems: "flex-end",
      padding: "40px 25px 0 25px",
    },
    containerVino: {
      [theme.breakpoints.up("md")]: {},
    },
    titulo: {
      color: color === "black" ? "white" : "#c28a6a",
      fontFamily: "Dis-Bold",
      fontSize: 30,
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "initial",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 36,
        letterSpacing: 2,
      },
    },
    subtitulo: {
      color: color === "black" ? "#333333" : "grey",
      fontFamily: "Rel-Medium",
      fontSize: 20,
      marginBottom: 20,
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "initial",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 24,
        letterSpacing: 3,
        marginBottom: 40,
      },
    },
    titulo_descripcion: {
      color: color === "black" ? "#333333" : "grey",
      fontFamily: "Rel-Bold",
      fontSize: 16,
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
        letterSpacing: 2,
      },
    },
    parrafo_descripcion: {
      color: color === "black" ? "#333333" : "grey",
      fontFamily: "Rel-Medium",
      fontSize: 14,
      [theme.breakpoints.up("lg")]: {
        fontSize: 15,
        letterSpacing: 1.1,
      },
    },
    img_puntos: {
      width: 190,
      margin: "auto",
      [theme.breakpoints.up("md")]: {
        margin: "0",
      },
      [theme.breakpoints.up("lg")]: {
        width: 270,
      },
    },
    img_cuadrados: {
      width: 60,
      margin: "20px auto",

      [theme.breakpoints.up("md")]: {
        margin: "0",
        marginBottom: 40,
      },
      [theme.breakpoints.up("lg")]: {
        width: 90,
      },
    },
    divider: {
      color: "#754c24",
      backgroundColor: "#754c24",
      margin: "10px 0",
      [theme.breakpoints.up("lg")]: {
        margin: "20px 0",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6} className={classes.container}>
      <img
        alt="cuadrados"
        className={classes.img_cuadrados}
        src={
          color === "black"
            ? require("../assets/vinos/cuadrados_oscuro.png")
            : require("../assets/vinos/cuadrados_claro.png")
        }
      />
      <Typography className={classes.titulo}>{titulo}</Typography>
      <Typography className={classes.subtitulo}>{subtitulo}</Typography>
      <Typography className={classes.titulo_descripcion}>
        COMPOSITION
      </Typography>
      <Typography className={classes.parrafo_descripcion}>
        {composition}
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.titulo_descripcion}>APELLATION</Typography>
      <Typography className={classes.parrafo_descripcion}>
        {apellation}
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.titulo_descripcion}>
        TASTING NOTES
      </Typography>
      <Typography className={classes.parrafo_descripcion}>{tasting}</Typography>
      <Divider className={classes.divider} />
      <img
        className={classes.img_puntos}
        alt={"puntos"}
        src={require(`../assets/vinos/${puntos}`)}
      />
      <Divider className={classes.divider} />
    </Grid>
  );
};

export default FichaVino;
