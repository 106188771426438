import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Portada from "../components/Portada";
import { Typography, Divider } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // background: "rgba(117,84,67,1)",
    // background:
    //   "-moz-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-webkit-gradient(left top, right top, color-stop(0%, rgba(117,84,67,1)), color-stop(59%, rgba(151,99,69,1)), color-stop(100%, rgba(174,110,71,1)))",
    background:
      "-webkit-linear-gradient(left, rgba(150,119,96,1) 0%, rgba(168,133,106,1) 38%, rgba(199,162,133,1) 100%)",
    // background:
    //   "-o-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "-ms-linear-gradient(left, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // background:
    //   "linear-gradient(to right, rgba(117,84,67,1) 0%, rgba(151,99,69,1) 59%, rgba(174,110,71,1) 100%)",
    // filter:
    //   "progid:DXImageTransform.Microsoft.gradient( startColorstr='#755443', endColorstr='#ae6e47', GradientType=1 )",
  },

  container: {
    position: "relative",
    maxWidth: 950,
    margin: "0 auto",
    // paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("lg")]: {
      //   paddingTop: 100,
      paddingBottom: 100,
      maxWidth: 1200,
    },
  },
  containerVino: {
    // display:"flex",
    textAlign: "center",
    // justifyContent:"center",
    [theme.breakpoints.up("md")]: {
      display: "grid",
      alingItem: "flex-end",
    },
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      alingItem: "flex-end",
    },
  },
  imagenVino: {
    height: 495,
    // margin: "0px auto",
    [theme.breakpoints.up("sm")]: {
      height: 650,
    },
    [theme.breakpoints.up("md")]: {
      height: 650,
      //   marginTop: 40,
    },
    [theme.breakpoints.up("lg")]: {
      height: 700,
      //   marginTop: 50,
    },
  },
  mancha: {
    position: "absolute",
    top: 0,
    height: 100,
    left: "50%",
    [theme.breakpoints.up("lg")]: {
      left: "70%",
      height: 300,
    },
  },

  //

  containerFicha: {
    textAlign: "initial",
    display: "grid",
    alignItems: "flex-end",
    padding: "40px 25px 0 25px",
  },
  containerVinoFicha: {
    [theme.breakpoints.up("md")]: {},
  },
  titulo: {
    color: "white",
    fontFamily: "Dis-Bold",
    fontSize: 30,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 36,
      letterSpacing: 2,
    },
  },
  subtitulo: {
    color: "#333333",
    fontFamily: "Rel-Medium",
    fontSize: 20,
    marginBottom: 10,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "initial",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      letterSpacing: 3,
      marginBottom: 10,
    },
  },
  titulo_descripcion: {
    color: "#333333",
    fontFamily: "Rel-Bold",
    fontSize: 16,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      letterSpacing: 2,
    },
  },
  parrafo_descripcion: {
    color: "#333333",
    fontFamily: "Rel-Medium",
    fontSize: 14,
    [theme.breakpoints.up("lg")]: {
      fontSize: 15,
      letterSpacing: 1.1,
    },
  },

  img_cuadrados: {
    width: 60,
    margin: "20px auto",

    [theme.breakpoints.up("md")]: {
      margin: "0",
      marginBottom: 40,
    },
    [theme.breakpoints.up("lg")]: {
      width: 90,
    },
  },
  divider: {
    color: "#754c24",
    backgroundColor: "#754c24",
    margin: "10px 0",
    [theme.breakpoints.up("lg")]: {
      margin: "20px 0",
    },
  },
}));
const Origin = () => {
  const classes = useStyles();
  const color = "black";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.root}>
       <Helmet>
        <title>Uco Valley. Mendoza. Argentina</title>
        <meta
          name="description"
          content="Our vineyards are located in one of the world's most exciting wine regions to be developed in the last 20 years. High altitudes, dry weather, alluvial soil, thermal amplitude, and meltwater from the Andes Mountains combine to create unique mountain desert terroirs."
        />
        <link
          rel="canonical"
          href="http://unanime.mascotavineyards.com/origin"
        />
      </Helmet>
      <Portada url={"origin.jpg"} />
      <Grid container className={classes.container}>
        <img
          className={classes.mancha}
          alt="mancha"
          src={require("../assets/vinos/mancha_beige.png")}
        />
        <Grid item xs={12} md={6} className={classes.containerVino}>
          <img
            className={classes.imagenVino}
            alt={"mapa origin"}
            src={require("../assets/mapa.png")}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.containerFicha}>
          <img
            alt="cuadrados"
            className={classes.img_cuadrados}
            src={
              color === "black"
                ? require("../assets/vinos/cuadrados_oscuro.png")
                : require("../assets/vinos/cuadrados_claro.png")
            }
          />
          <Typography className={classes.subtitulo}>ORIGIN</Typography>
          <Typography className={classes.titulo}>
            Uco Valley, Mendoza,
            <br />
            Argentina
          </Typography>

          <Divider className={classes.divider} />

          <Typography className={classes.parrafo_descripcion}>
            {/* Nuestros viñedos se encuentran en una de las regiones vitivinícolas
            mundiales con mayor desarrollo de los últimos 20 años. El clima
            seco, el suelo aluvional, la altura y la amplitud térmica favorecen
            la maduración y la concentración de taninos, conformando un lugar
            donde los viñedos se expresan al máximo, regados por el agua cercana
            de los Andes. */}
            Our vineyards are located in one of the world's most exciting wine
            regions to be developed in the last 20 years. High altitudes, dry
            weather, alluvial soil, thermal amplitude, and meltwater from the
            Andes Mountains combine to create unique mountain desert terroirs.
            This special place gives life to our Unanime wines, with ripe
            tannins, concentrated flavors and rich personality.
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Origin;
