import React, { useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Link } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  container: {
    zIndex: 10,
    border: "1px solid #d59570",
    position: "relative",
    width: 125,
    height:125,
    margin: "5px auto",
    textAlign: "center",
    backgroundColor: "transparent",

    [theme.breakpoints.up("sm")]: {
      width: 250,
      height: 250,
      margin: "0 auto",
    },

    [theme.breakpoints.up("lg")]: {
      width: 300,
      height: 300,
    },
    
  },
  cuadro: {
    cursor: "pointer",
    zIndex: 30,
    position: "absolute",
    overflow:"hidden",
    // border: "1px solid #d59570",
   
    width: 115,
    height: 115,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    textAlign: "center",
    backgroundColor: "transport",

    [theme.breakpoints.up("sm")]: {
      width: 235,
      height: 235,
    },

    [theme.breakpoints.up("lg")]: {
      width: 285,
      height: 285,
    },
  },
  zoom: {
    zIndex: 20,
    position: "absolute",
    transform:"scale(1.1)",
    // cursor: "pointer",
    width: 115,
    height: 115,
    transition: "all 1s ease 0s",
    // transform: "scale(1.4)",
    // "&:hover": {
    //   transform: "scale(1.2)",
    // },
    [theme.breakpoints.up("sm")]: {
      width: 240,
      height: 240,
    },

    [theme.breakpoints.up("lg")]: {
      width: 290,
      height: 290,
    },
  }
}));
const ZoomInHome = ({ image, url }) => {
  const classes = useStyles();
  const history = useHistory()
  const zoom = useRef(null);
  const zoomIn = () => {
    zoom.current.style.webkitTransform = "scale(1)";
  };
  const zoomOut = (posicion) => {
    zoom.current.style.webkitTransform = "scale(1.1)";
  };
  return (
    <div className={classes.container}>
      <div
        className={classes.cuadro}
        onMouseEnter={zoomIn}
        onMouseLeave={zoomOut}
        onClick={()=>history.push(url)}
      >

      <Link to={url} component={RouterLink} style={{ zIndex: 10 }}>
        <div  ref={zoom} className={classes.zoom}>
          <img  style={{width:"100%"}} alt={"vino-url"} src={image} />
        </div>
      </Link>
      </div>
    </div>
  );
};

export default ZoomInHome;
